/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Table, Spin, Button, Input } from 'antd'
import { ClearOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
  onGetVatTypeList,
  onGetPhysicalStockZoneList,
  onGetPhysicalStockShelfList,
} from 'redux/actions'

import {
  addCommas,
} from 'utils'
import './style.scss'
import useListProduct from './hooks/useListProduct'
import usePagination from 'hooks/usePagination'

const Search = Input.Search

const ListProductPage = () => {
  const {
    currentPageNumber,
    pageSize,
    paginationSetting,
    setCurrentPageNumber,
  } = usePagination()
  const {
    productsData,
    isLoadingProductsData,
    pagination,
    onSearchFilerProducts,
    onSearchProductCode,
  } = useListProduct({
    currentPageNumber,
    pageSize,
  })
  const columns = [
    {
      title: 'รหัสสินค้า',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => (
        <Link to={`/no-vat/product?code=${record.code}`} target="_blank">
          {text}
        </Link>
      ),
      sorter: (a, b) => ('' + a.code).localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
      width: '100px',
      fixed: 'left',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'name',
      key: 'name',
      width: '400px',
      sorter: (a, b) => ('' + a.name).localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      fixed: 'left',
    },
    {
      title: 'ขนาดบรรจุ',
      dataIndex: 'package_size',
      key: 'package_size',
      width: '120px',
      render: (text) => <div className="text-align-right pr-2">{text}</div>,
    },
    {
      title: 'หน่วย',
      dataIndex: 'unit_name',
      key: 'unit_name',
      width: '80px',
    },
    {
      title: 'VAT',
      dataIndex: 'vat_type',
      key: 'vat_type',
      width: '50px',
      render: (vat_id) => <div>{displayVatType(vat_id)}</div>,
    },
    {
      title: 'สต็อก1',
      dataIndex: 'stock1',
      key: 'stock1',
      width: '110px',
      sorter: (a, b) => Number(a.stock1) - Number(b.stock1),
      sortDirections: ['ascend', 'descend'],
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'สต็อก2',
      dataIndex: 'stock2',
      key: 'stock2',
      width: '90px',
      sorter: (a, b) => Number(a.stock2) - Number(b.stock2),
      sortDirections: ['ascend', 'descend'],
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'จำนวนคงเหลือต่ำสุด',
      dataIndex: 'stock_low',
      key: 'stock_low',
      width: '140px',
    },
    {
      title: 'ราคาขายส่ง',
      dataIndex: 'price_out_atm',
      key: 'price_out_atm',
      width: '120px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ส่วนลดราคาส่ง',
      width: '150px',
      render: (_, record) => {
        return `${Number(record.discount_atm_1)}+${Number(
          record.discount_atm_2
        )}+${Number(record.discount_atm_3)}+${Number(record.discount_atm_4)}`
      },
    },
    {
      title: 'ราคาขายปลีก',
      dataIndex: 'price_out_cash',
      key: 'price_out_cash',
      width: '120px',
      render: (text) => (
        <div className="text-align-right pr-2">{addCommas(text)}</div>
      ),
    },
    {
      title: 'ส่วนลดราคาปลีก',
      width: '150px',
      render: (_, record) => {
        return `${Number(record.discount_cash_1)}+${Number(
          record.discount_cash_2
        )}+${Number(record.discount_cash_3)}+${Number(record.discount_cash_4)}`
      },
    },
    {
      title: 'โซนที่เก็บสินค้า',
      dataIndex: 'physical_stock_zone',
      key: 'physical_stock_zone',
      width: '110px',
      render: (zone_id) => <div>{displayDataFromArray(zone_id, 'zone')}</div>,
    },
    {
      title: 'ชั้นวาง',
      dataIndex: 'physical_stock_shelf',
      key: 'physical_stock_shelf',
      width: '110px',
      render: (shelf_id) => (
        <div>{displayDataFromArray(shelf_id, 'shelf')}</div>
      ),
    },
  ]

  const [searchText, setSearchText] = useState('')
  const [searchCode, setSearchCode] = useState('')
  const vatTypes = useSelector((state) => state.getVatTypeList.data)
  const physicalStockZones = useSelector(
    (state) => state.getPhysicalStockZoneList.data
  )
  const physicalStockShelfs = useSelector(
    (state) => state.getPhysicalStockShelfList.data
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetPhysicalStockZoneList())
    dispatch(onGetPhysicalStockShelfList())
    dispatch(onGetVatTypeList())
    document.title = 'รายการสินค้าทั้งหมด - NoVat'
  }, [])

  const handleSearch = (searchText) => {
    setCurrentPageNumber(1)
    onSearchFilerProducts({ searchTerm: searchText.toLowerCase() })
    onSearchProductCode({ productCode: undefined })
  }

  const handleSearchCode = (searchCode) => {
    setCurrentPageNumber(1)
    onSearchProductCode({ productCode: searchCode.toLowerCase() })
    onSearchFilerProducts({ searchTerm: undefined })
  }

  const displayDataFromArray = (id, place) => {
    let textRender = ''
    if (place === 'zone') {
      physicalStockZones.forEach((zone) => {
        if (zone.id === id) {
          textRender = zone.name
        }
      })
    } else {
      physicalStockShelfs.forEach((shelf) => {
        if (shelf.id === id) {
          textRender = shelf.name
        }
      })
    }
    return textRender
  }

  const displayVatType = (id) => {
    let typeRender = ''
    vatTypes.forEach((type) => {
      if (type.id === id) {
        typeRender = type.name
      }
    })
    return typeRender
  }

  const onClearSearch = () => {
    setCurrentPageNumber(1)
    onSearchFilerProducts({ searchTerm: undefined })
    onSearchProductCode({ productCode: undefined })
    setSearchText('')
    setSearchCode('')
  }

  return (
    <div>
      <Spin spinning={isLoadingProductsData}>
        <h1>รายการสินค้าทั้งหมด</h1>
        <div className="mb-2">
          <Search
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value)
              setSearchCode('')
            }}
            placeholder="คำค้นหา"
            onSearch={handleSearch}
            style={{ width: 200, marginRight: 10 }}
          />
          <Search
            value={searchCode}
            onChange={(event) => {
              setSearchCode(event.target.value)
              setSearchText('')
            }}
            placeholder="ค้นหาจากรหัส"
            onSearch={handleSearchCode}
            style={{ width: 200, marginRight: 10 }}
          />
          <Button
            type="primary"
            icon={<ClearOutlined />}
            onClick={onClearSearch}
          >
            เคลียร์ผลการค้นหา
          </Button>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={productsData}
          size="small"
          scroll={{ x: 1500, y: 680 }}
          style={{ height: '690px' }}
          pagination={paginationSetting({
            totalItems: pagination?.totalItems,
            simple: true,
          })}
        />
      </Spin>
    </div>
  )
}

export default ListProductPage
